import React from 'react'
import { Nav, Image, Row, Col } from 'react-bootstrap'
import SidebarItems from './sidebarItems'
import { NavLink } from 'react-router-dom'

export default function Sidebar(props) {

  return (
    <>
      <Row className="text-center mb-2">
        <Col md={12}>
          <Image src={props.profile.photoURL} height='96px' width='96px' style={{ objectFit: 'cover' }} roundedCircle />
        </Col>
        <Col md={12} className="pt-2">
          <h5>{props.profile.displayName}</h5>
        </Col>
      </Row>
      <Nav variant="pills" className="flex-column profile-sidebar">
        {
          SidebarItems.map((item, index)=> {
            return (
              <Nav.Item key={item.name}>
                <Nav.Link as={NavLink} to={item.route} eventKey={item.name}>{item.name}</Nav.Link>
              </Nav.Item>
            )
          })
        }
      </Nav>
    </>
  )
}
