import React, { useState, useEffect } from 'react'
import Layout from '../components/layout/layout'
import Footer from '../components/layout/footer'
import { firestore } from 'gatsby-theme-firebase'
import { Container, Row, Tab } from 'react-bootstrap'
import Dashboard from '../components/profile/content/dashboard'
import Bagdes from '../components/profile/content/badges'
import Settings from '../components/profile/content/settings'
import DangerZone from '../components/profile/content/dangerZone'
import LayoutProfile from '../components/profile/layout'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { EnsurePagePermission } from '../components/auth'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

export default function Profile(props ) {

  const [profile, setProfile] = useState()
  const [isAllowed, setIsAllowed] = useState(false)
  EnsurePagePermission('profile', setIsAllowed, setProfile)

  const [places, setPlaces] = useState([])

  useEffect(() => {

    const placesRef = firestore.collection('pin_places')

    // console.log('fire')

    if (profile !== undefined ) (
      placesRef.where('contributor.uid', '==', profile.uid).onSnapshot((querySnapshot) => {
        const items = []
        querySnapshot.forEach((doc) => {
          items.push({
            data: doc.data(),
            pid: doc.data().properties.pid,
            title: doc.data().properties.title,
            verified: doc.data().properties.verified,
            activation: doc.data().properties.activationType,
            active: doc.data().properties.active,
            lat: doc.data().geometry.coordinates.latitude,
            lng: doc.data().geometry.coordinates.longitude,
            icon: doc.data().properties.type,
            country_code: doc.data().address.country_code,
            id: doc.id
          })
        })
        setPlaces(items)
      })
    )

  }, [profile])

  return (
    isAllowed &&
    <Layout>
      <GatsbySeo
        titleTemplate='Profile | %s'
      />
      <div className="main-container">
        <Container>
          <Tab.Container id="profile" defaultActiveKey="Dashboard">
            <Row className="my-5">
              <Router
                basename='/profile'
                forceRefresh={false}
              >
                <LayoutProfile profile={profile}>
                  <Switch>
                    <Redirect exact from='/' to='/dashboard'/>
                    <Route path='/dashboard' component={() => <Dashboard profile={profile} places={places} key={window.location.pathname} /> } />
                    <Route path='/badges' component={() => <Bagdes profile={profile} key={window.location.pathname}/> } />
                    <Route path='/settings' component={() => <Settings profile={profile} key={window.location.pathname}/> } />
                    <Route path='/danger-zone' component={() => <DangerZone profile={profile} key={window.location.pathname}/> } />
                  </Switch>
                </LayoutProfile>
              </Router>
            </Row>
          </Tab.Container>
        </Container>
        <Footer />
      </div>
    </Layout>
  )

}
