import React from 'react'
import Sidebar from './sidebar/sidebar.jsx'
import { Col } from 'react-bootstrap'

export default function Layout(props) {

  return (
    <>
      <Col md={3}>
        <Sidebar profile={props.profile} />
      </Col>
      <Col md={9} className='profile-content'>
        {props.children}
      </Col>
    </>
  )
}
