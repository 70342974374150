import React from 'react'
import { Row, Col, Card, Tab, Alert, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faAnchor } from '@fortawesome/free-solid-svg-icons'
import DataTable from 'react-data-table-component'

export default function Dashboard(props) {

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
      },
    },
    subHeader: {
      style: {
        maxHeight: '52px',
        marginTop: '15px',
        marginBottom: '15px',
      },
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: '#3771c82b',
        borderBottomColor: '#FFFFFF',
        borderRadius: '10px',
        outline: '1px solid #FFFFFF',
      },
    },
  }

  const columns = [
    {
      name: 'Place ID',
      selector: 'pid',
      sortable: false,
      wrap: true,
      minWidth: '140px',
      style: {
        color: 'rgba(0,0,0,.54)',
        fontSize: '10px',
      },
    },
    {
      selector: 'icon',
      sortable: false,
      width: '60px',
      cell: d => <img
        className="my-2"
        height="40"
        width="32"
        alt={props.places.title} src={`../assets/pin-icon/${d.icon}.svg`}
      />,
    },
    {
      name: 'Name',
      selector: 'title',
      sortable: true,
      width: '170px',
      style: {
        color: '#202124',
        fontSize: '14px',
        fontWeight: 500,
      },
    },
    {
      selector: row => <> {
        (() => {
          if (row.active === '0')
            return <Badge pill bg="light" text="dark" row={row}>inactive</Badge>
          else if (row.active === '1')
            return <Badge pill bg="primary" row={row}>active</Badge>
          else if (row.active === '2')
            return <Badge pill bg="dark" row={row}>unknown state</Badge>
        })()
      } </>,
      sortable: false,
      width: '115px',
      grow: 0,
    },
    {
      selector: row => row.verified ? <Badge pill bg="success" row={row}>verified</Badge> : <Badge pill bg="danger" row={row}>not verified</Badge>,
      sortable: false,
      grow: 0,
    },
    {
      right: true,
      width: '80px',
      selector: row =>   <>
        <span className="pointer" title="show on map" onClick={() => window.location.href = `/map?lat=${row.lat}&lng=${row.lng}&z=17`}>
          <FontAwesomeIcon icon={faMapMarkedAlt} style={{color:'#0b182a'}} size='lg' />
        </span>
      </>,
    },
  ]

  return (
    <Tab.Content>
      <Tab.Pane eventKey="Dashboard">
        <Row>
          <Col md={12}>
            <h5>Dashboard</h5>
            <hr />
          </Col>
        </Row>
        <Row className="profile-dashboard">
          <Col md={12}>
            <Alert variant="success">
              <p>
                Pinavo is in the <span role="img" aria-label="test tube">🧪</span> testing phase.
              </p>
              <hr />
              <p className="mb-0">
                <b><a href="https://koen.ecomailapp.cz/public/form/6-3fdfd544852ed7431aa64f3b9481afb9">Sign up for the newsletter</a></b> so you don't miss the latest information and announcements or see our <b><a href="/follow-us">Follow us</a></b> page and choose the information channels that suits you.
              </p>
            </Alert>
          </Col>
          <Col md={12} className="py-3">
            <Card>
              <Card.Body>
                <Card.Title>My places</Card.Title>
                <DataTable
                  columns={columns}
                  data={props.places}
                  defaultSortField="pid"
                  defaultSortAsc={false}
                  pagination
                  customStyles={customStyles}
                  highlightOnHover
                  noHeader
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md={12}>
            <Card>
              <Card.Body><FontAwesomeIcon icon={faAnchor} />&nbsp; You are not part of any <a href="/shallop">Shallop</a> group yet. Coming soon.</Card.Body>
            </Card>
            <hr/>
            <p>On board since {props.profile.metadata.creationTime}</p>
          </Col>
        </Row>
      </Tab.Pane>
    </Tab.Content>
  )

}
