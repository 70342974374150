import React from 'react'
import { Row, Col, Tab } from 'react-bootstrap'

export default function Badges(props) {

  return (
    <Tab.Content>
      <Tab.Pane eventKey="Badges">
        <Row>
          <Col md={12}>
            <h5>Badges</h5>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <p>🚧 soon</p>
          </Col>
        </Row>
      </Tab.Pane>
    </Tab.Content>
  )
}
