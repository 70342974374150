const SidebarItems = [
  {
    name: 'Dashboard',
    route: '/dashboard'
  },
  /* {
    name: 'Badges',
    route: '/badges',
  },*/
  {
    name: 'Settings',
    route: '/settings',
  },
  {
    name: 'Danger zone',
    route: '/danger-zone',
  },
]

export default SidebarItems
