import React from 'react'
import { Row, Col, Tab, Form, Card } from 'react-bootstrap'

export default function Settings(props) {

  return (
    <Tab.Content>
      <Tab.Pane eventKey="Settings">
        <Row>
          <Col md={12}>
            <h5>Settings</h5>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>User</Card.Title>
                <Form>
                  <Form.Group as={Row} className="mb-2">
                    <Form.Label column sm="2">
                            Full name
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control readOnly defaultValue={props.profile.displayName} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                            Email
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control readOnly defaultValue={props.profile.email} />
                    </Col>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Provider</Card.Title>
                <Form>
                  <Form.Group as={Row} className="mb-2">
                    <Form.Label column sm="2">
                            Signed in by
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control readOnly defaultValue={props.profile.providerData[0].providerId} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                            Verified email
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control readOnly defaultValue={props.profile.emailVerified} />
                    </Col>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Tab.Pane>
    </Tab.Content>
  )
}
