import React from 'react'
import { Row, Col, Tab } from 'react-bootstrap'

export default function DangerZone(props) {

  return (
    <Tab.Content>
      <Tab.Pane eventKey="Danger zone">
        <Row>
          <Col md={12}>
            <h5>Danger zone</h5>
            <hr />
          </Col>
        </Row>
        <Row className="common-link">
          <Col md={12}>
            <p>Do you want to delete the account? Let us know at <a href="info@pinavo.cz">info@pinavo.cz</a>.</p>
            <p>We'd just wanted to add that we are glad you are part of the Pinavo community.</p>
          </Col>
        </Row>
      </Tab.Pane>
    </Tab.Content>
  )
}
